<template>
  <v-dialog v-model="show" width="500" class="mb-5 pb-5" persistent>
      <v-card class="pb-5">
        <v-card-title class="secondary_white">Reset API Token</v-card-title>
        <p class="pl-5 pr-5 mt-5 mb-5" style="font-size:16px"> Reseting the API token will nullify your current token.Are you sure you want to continue? </p>
        <v-text-field outlined dense class="mr-5 ml-5" label="Set Validity in number of days" v-model="validity"></v-text-field>
        <v-layout justify-space-between class="mr-5 ml-5">
          <v-btn text small color="" @click="cancel">Cancel</v-btn>
          <v-btn color="primary" small @click="resetToken">Reset</v-btn>

        </v-layout>
        </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ['show'],
  data: () => ({
    validity: 365,
  }),

  methods: {
    resetToken: function(){
      if(this.validity > 0){
      this.$store.dispatch('resetAccessKey', this.validity)}
    },

    cancel: function(){
      this.$emit('close')
    }
  }



}
</script>

<style>

</style>